<template>
  <div class="pa-4" v-if="this.$store.state.user.role.startsWith('management')">
    <v-container fluid>
      <h2 text color="black" class="headline">運営主体スタッフ管理</h2>
      <v-row dense>
        <v-col
          cols="12" md="12" sm="12" xl="12"
        >
          <v-toolbar
            color="primary"
            flat
            dark
            class="pa-2 top-rounded"
            height="80px"
            v-if="isAdminRole"
          >
            <v-select
              hide-details
              eager
              v-model="filterSelectedField"
              label="削除済み"
              :items="filterFields"
              style="max-width: 200px"
              @change="filterItems"
            ></v-select>
            <v-spacer />
            <v-select
              hide-details
              eager
              v-model="searchSelectedField"
              label="検索フィールド"
              :items="searchFields"
              style="max-width: 200px"
              @change="subscribeItems"
            ></v-select>
            <v-spacer />
            <v-text-field
              v-if="searchSelectedField != '権限の種別'"
              class="mt-4"
              full-width
              label="前方一致検索キーワード"
              outlined
              clearable
              v-model="searchKeyword"
              prepend-inner-icon="search"
              @blur="subscribeItems"
              @keyup.enter="subscribeItems"
            >
            </v-text-field>
            <v-radio-group
              v-if="searchSelectedField == '権限の種別'"
              v-model="accessTypeModel"
              row
              @change="subscribeItems"
              >
              <v-radio label="全てのユーザー" value="all"></v-radio>
              <v-radio label="管理ユーザー" value="admin"></v-radio>
              <v-radio label="一般ユーザー" value="normal"></v-radio>
            </v-radio-group>
            <v-spacer />
          </v-toolbar>
          <v-data-table
            :headers="headers"
            :items="filteredItems"
            :items-per-page.sync=selectedLinesPerPage
            hide-default-footer
            :sort-by="sortBy"
            @update:sort-by="updateSortBy"
            fixed-header
            height="600"
          >
            <template v-slot:top="{ pagination, options, updateOptions }">
              <v-data-footer
                :pagination="pagination"
                :options="options"
                @update:options="updateOptions"
                items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                :items-per-page-options="linesPerPageList"
                class="border-less"
              />
            </template>
            <template v-slot:item.action="{ item }">
              <v-btn icon @click="viewItemForm(item)" >
                <v-icon class="mx-3">remove_red_eye</v-icon>
              </v-btn>
              <v-btn icon @click="editItemForm(item)" >
                <v-icon class="mx-3">edit</v-icon>
              </v-btn>
              <v-btn
                icon
                @click="deleteItem(item)"
                v-if="isAdminRole && !item.deleted"
              >
                <v-icon class="mx-3">delete</v-icon>
              </v-btn>
              <v-btn
                icon
                @click="revertItem(item)"
                v-if="item.deleted"
              >
                <v-icon class="mx-3">restore</v-icon>
              </v-btn>
            </template>
            <template v-slot:item.role="{ item }">
              {{roleNames[item.role]}}
            </template>
            <template v-slot:item.is_email_off="{ item }">
              {{ item.is_email_off ? '受信しない': '受信する' }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row v-if="$store.state.user.role == 'management-admin'">
        <v-fab-transition>
          <v-btn
            id="rb-fab"
            color="red"
            large
            dark
            absolute
            right
            fab
            class="rb-fab"
            @click="newItemForm"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-fab-transition>
      </v-row>
    </v-container>
    <mgt-staff-dialog
      v-if="mode != 'none'"
      v-model="userDialogModel"
      :mode="mode"
      :item="currentItem"
      :orgId="orgId"
      :org-name="orgName"
      staffType="management"
      @closed="onDialogClosed"
      @not-deleted="itemNotDeleted"
    />

  </div>
</template>

<script>
import dateformat from 'dateformat';
import MgtStaffDialog from '@/components/MgtStaffDialog.vue';


let unsubscribeItems = null;
let itemsName = 'staffs';
let itemsRef = null;

export default {
  name: 'MgtStaffs',
  components: {
    MgtStaffDialog
  },
  data() {
    return {
      userDialogModel: false,
      headers: [
        {
          text: '操作',
          align: 'left',
          sortable: false,
          value: 'action',
          width: '210px',
        },
        {
          text: 'Email',
          align: 'left',
          sortable: true,
          value: 'email',
          width: '160px',
        },
        {
          text: '氏名',
          align: 'left',
          sortable: true,
          value: 'name',
          width: '140px',
        },
        {
          text: 'ふりがな',
          align: 'left',
          sortable: true,
          value: 'name_kana',
          width: '140px',
        },
        {
          text: '電話番号',
          align: 'left',
          sortable: true,
          value: 'phone_number',
          width: '120px',
        },
        {
          text: '権限の種別',
          align: 'left',
          sortable: true,
          value: 'role',
          width: '120px',
        },
        {
          text: '通知メール',
          align: 'left',
          sortable: true,
          value: 'is_email_off',
          width: '120px',
        },
        {
          text: '登録日時',
          align: 'left',
          sortable: true,
          value: 'created',
          width: '100px',
        },
      ],
      emailRules: [
        v => !!v || '入力は必須です。',
        v => /.+@.+\..+/.test(v) || 'Emailの形式が正しくありません。',
      ],
      nameRules: [
        v => !!v || '入力は必須です。',
      ],
      postalCodeRules: [
        v => !!v || '入力は必須です。',
      ],
      prefRules: [
        v => !!v || '入力は必須です。',
      ],
      cityRules: [
        v => !!v || '入力は必須です。',
      ],
      uniqueIdRules: [],
      remarksRules: [],
      items: [],
      filteredItems: [],
      currentItem: {},
      mode: 'none',
      recordDialogTitle: '登録',
      configRobots: {},
      modelNumbers: [],
      searchSelectedField: 'Emailアドレス',
      searchFields: ['Emailアドレス', '氏名', '氏名（ふりがな）', '電話番号', '権限の種別', '備考'],
      searchKeyword: '',
      filterSelectedField: '削除済み非表示',
      filterFields: ['全て表示', '削除済みのみ表示', '削除済み非表示'],
      accessTypeModel: 'all',
      roleNames: {
        management: '一般ユーザー',
        'management-admin': '管理ユーザー'
      },
      orgId: 'OsgIJjpamsvOS4GiLaFJ',
      selectedLinesPerPage: 10,
      linesPerPageList: [10, 30, 50],
      orgName: null,
      sortBy: ['name'],
    };
  },
  computed: {
    logDateFormatted() {
      return this.logDate.toLocaleDateString().replace(/\//g, '-');
    },
    isAdminRole() {
      return this.$store.state.user.role == 'management-admin';
    }
  },
  methods: {
    closeDialog() {
    },
    newItemForm() {
      this.mode = 'new';
      this.userDialogModel = true;
    },
    viewItemForm(item) {
      // console.log('viewItemForm', item);
      this.mode = 'view';
      this.currentItem = item;
      this.userDialogModel = true;
    },
    editItemForm(item) {
      this.mode = 'edit';
      this.currentItem = item;
      this.userDialogModel = true;
    },
    deleteItem(item) {
      this.mode = 'delete';
      this.currentItem = item;
    },
    revertItem(item) {
      this.mode = 'revert';
      this.currentItem = item;
    },
    onDialogClosed() {
      // 削除完了後に呼び出されるCallback
      this.mode = 'none';
      this.currentItem = null;
    },
    itemNotDeleted() {
      // 削除完了後に呼び出されるCallback
      this.mode = 'none';
      // console.log('item-not-deleted');
    },
    subscribeItems() {
      itemsRef = this.$db.collection(`${itemsName}`);
      // 購読中は一旦解除した上で対応する。
      if (unsubscribeItems) unsubscribeItems();

      // 運営主体スタッフでなければ終了
      if (!this.$store.state.user.role.startsWith('management')) return;

      this.searchKeyword = this.searchKeyword ? this.searchKeyword.trim(): '';

      let cRef = itemsRef;
      if (this.$store.state.user.role == 'management-admin') {
        // 管理ユーザーの場合
        cRef = cRef.where('org_id', '==', this.orgId);
      } else {
        // 一般ユーザーの場合
        cRef = cRef.where('email', '==', this.$store.state.user.email);
      }

      if (this.searchSelectedField != '権限の種別' && this.searchKeyword.length > 0) {
        // 検索キーワードで前方検索
        let startAt = this.searchKeyword;
        let endAt = this.searchKeyword + '\uffff';
        let searchFieldsValues = {
          'Emailアドレス':'email',
          '氏名': 'name',
          '氏名（ふりがな）': 'name_kana',
          '電話番号': 'phone_number',
          '備考': 'remarks'
        };
        cRef = cRef.orderBy(searchFieldsValues[this.searchSelectedField]).startAt(startAt).endAt(endAt);
      } else if (this.searchSelectedField == '権限の種別' && this.accessTypeModel != 'all' ) {
        // 権限の種別でフィルタ
        let role = this.accessTypeModel == 'admin' ? 'management-admin' : 'management';
        cRef = cRef.where('role', '==', role);
      } 

      // 最大件数の制限
      cRef = cRef.limit(this.$store.state.configs.query_limit);

      unsubscribeItems = cRef
        .onSnapshot((querySnapshot) => {
            // 最初および変更時の読み取り
            let items = [];
            querySnapshot.forEach((doc) => {
              let data = doc.data();
              data.created = data.created ? dateformat(new Date(data.created.seconds * 1000), 'yyyy/mm/dd HH:MM:ss'): '';
              data.modified = data.modified ? dateformat(new Date(data.modified.seconds * 1000), 'yyyy/mm/dd HH:MM:ss'): '';

              for (const key in data.terms_of_services) {
                const service = data.terms_of_services[key];
                const pdf = this.getTermsOfServicesPdfInfo(key);
                if (!pdf) {
                  delete data.terms_of_services[key];
                  continue;
                }
                service['label'] = pdf.label;
                service['datetime'] = this.timestampToString(service['datetime']);
              }
              
              data.id = doc.id;
              items.push(data);
            });
            this.items = items.reverse();
            this.filterItems();
            //console.log('onSNapshot', this.items);
        }, (e) => {
          console.log('error', e);
          this.items = [];
        });
    },
    filterItems() {
      if (this.filterSelectedField === '全て表示') {
        this.filteredItems = this.items;
        return;
      }
      if (this.filterSelectedField === '削除済み非表示') {
        const filteringItem = [];
        for (const item of this.items) {
          if (!item.deleted) {
            filteringItem.push(item);
          }
        }
        this.filteredItems = filteringItem;
        return;
      }
      if (this.filterSelectedField === '削除済みのみ表示') {
        const filteringItem = [];
        for (const item of this.items) {
          if (item.deleted) {
            filteringItem.push(item);
          }
        }
        this.filteredItems = filteringItem;
        return;
      }
    },
    updateLogDate() {
      // console.log('updateLogDate');
    },
    getTermsOfServicesPdfInfo(serviceType) {
      for (const pdf of this.$store.state.termsOfServicePdfs) {
        const pdfServiceType = pdf.name.split('-')[1];
        if (pdfServiceType == serviceType) return pdf;
      }
      return null;
    },
    timestampToString(timestamp, format='yyyy/mm/dd HH:MM'){
      try {
        return timestamp ? dateformat(new Date(timestamp.seconds * 1000), format): null;
      } catch (e) {
        return null;
      }
    },
    updateSortBy(v) {
      this.sortBy = v;
    },
  },
  async mounted() {
    // テーブル表示件数の初期読み込み
    if (this.$store.state.user.table_lines_par_page) {
      this.selectedLinesPerPage = this.$store.state.user.table_lines_par_page;
    }
    // 他の画面で表示件数が変更された場合、こちらの画面にも反映させる
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'setUserTableLinesParPage') {
        this.selectedLinesPerPage = state.user.table_lines_par_page;
      }
    });

    let snapshot = await this.$db.collection('staff_orgs').where('type', '==', 'management').get();
    if (snapshot.empty) return;
    snapshot.forEach((doc) => {
      // 運営主体は一組織しかない前提
      this.orgId = doc.id;
      this.orgName = doc.data().name;
    });
    this.subscribeItems();
    this.$functions.log({ tags: [itemsName, 'list'], message: `${this.orgName}：${this.orgName}：所属スタッフ一覧を表示しました。` });
  },
  beforeDestroy() {
    // 変更監視を停止
    if (unsubscribeItems) unsubscribeItems();
  },
  watch: {
    selectedLinesPerPage(lineNumber) {
      if (this.$store.state.user.table_lines_par_page != lineNumber) {
        const staffsRef = this.$db
          .collection('staffs')
          .doc(this.$store.state.user.id);
        staffsRef.update({
          table_lines_par_page: lineNumber
        });
        this.$store.commit('setUserTableLinesParPage', lineNumber);
      }
    }
  },
}
</script>

<style scoped>
.theme-color {
  color: white;
  background-color:  #B3131A;
}

.top-rounded {
  margin:0px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.border-less {
  border: none !important;
}
</style>